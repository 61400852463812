@media (max-width:400px) {
	
}
@media (max-width: 767px) {
	
}
@media (min-width: @screen-sm-min) 
{
	
}
@media (max-width: @screen-md) 
{
	
	.div_logo
	{
		width:174px;
		height:40px;
		top:25px;
		
	}
	.div_header
	{
		position:relative;
		height:90px;
		background-color:#ffffff;
		border-bottom:4px solid #56bebf;
		z-index:2000;
	}
	.div_logo
	{
		
		position:absolute;
		margin:0;
		top:21px;
		right:21px;
	}
	.row-offcanvas 
	{
		position: relative;
		-webkit-transition: all .25s ease-out;
		-o-transition: all .25s ease-out;
		transition: all .25s ease-out;
	}
	.row-offcanvas-top 
	{
		top: -100vh;
	}
	.row-offcanvas-top.active 
	{
		top: 0px;
	}
	.sidebar
	{
		position:relative;
		width:100%;
		height:90px;
		border-right:0px!important;		
	}
	.div_taalkeuze
	{
		padding-top:30px;
		margin-top:0px;
	}
	.div_datum
	{
		margin-bottom:50px;
	}
	.sidebar-offcanvas 
	{
		display: block;
		overflow-x: hidden;
		overflow-y: auto;
		position: absolute;
		top: 0;
		transition: all 0.25s ease-in-out 0s;
		width: 100%;
		z-index: 1000;
		height:auto!important;
	}
  	.sidebar-offcanvas nav
 	{
		position:relative;
		background-color: #ffffff;
	}
	.div_burger_menu
	{
		width:25px;
		height:35px;
		position:absolute;
		display:block;
		left:28px;
		top:41px;
		cursor: pointer; 
	}
	#nav-toggle span, #nav-toggle span:before, #nav-toggle span:after
	{
	  cursor: pointer;
	  border-radius: 1px;
	  height: 5px;
	  width: 35px;
	  background: #56bebf;
	  position: absolute;
	  display: block;
	  content: '';
	}
	#nav-toggle span:before{
	  top: -10px; 
	}
	#nav-toggle span:after {
	  bottom: -10px;
	}
	
	#nav-toggle span, #nav-toggle span:before, #nav-toggle span:after{
	  transition: all 500ms ease-in-out;
	}
	#nav-toggle.active span{
	  background-color: transparent;
	}
	#nav-toggle.active span:before, #nav-toggle.active span:after {
	  top: 0;
	}
	#nav-toggle.active span:before {
	  transform: rotate(45deg);
	}
	#nav-toggle.active span:after {
	  transform: rotate(-45deg);
	}
	
	.main
	{
	padding-left: 0;
	}
	.sidebar
	{
	
		ul.main_nav
		{
			margin-left:0;
			padding-left:0;
			li
			{
				width:100%;
				background:url(../../images/menu_border.png) no-repeat center bottom!important;
				a
				{
					width:100%;
					text-align:center;	
					
				}
				&:last-child
				{
					background:none;
				}
			}
		}
	}
}
@media (min-width: @screen-md-min) 
{
	.row-offcanvas,
	.sidebar-offcanvas
	{
		height:100%!important;
	}
	.sidebar-offcanvas nav
	{
		height:auto!important;
	}
	.div_sub_nav
	{
		position:absolute;
		left:0;
		bottom:0px;
		width:315px;
		text-align:center;
	}
	.div_taalkeuze
	{
		position:absolute;
		top:130px;
		left:0;
		width:315px;	
		text-align:center;
	}
	.div_datum
	{
		position:absolute;
		top:160px;
		left:0;
		width:315px;	
		text-align:center;
	}
	.div_header
	{
		position:absolute;
		width: 315px;
		top:53px;
		left:0;
		height:48px;
	}
	.div_logo
	{
		margin:0 auto 0 auto;
	}
	.div_datum
	{
		margin-bottom:50px;
	}
	.sidebar
	{
	
		ul.main_nav
		{
			li
			{
				a
				{
					&.active
					{
						&:before
						{
							content: '\e806';
							.fontello;
							color:@brand-primary!important;
							
						}
					}  
				}
			}
		}
	}	
}
@media (min-width: @screen-lg-min) 
{
	
}
	

	