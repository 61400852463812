html,
body 
{
	height:100%;
	overflow-x: hidden;
}

.div_menu
{
	position:fixed;
	left:0;
	top:0;
	background:#5FCC24;
	height:100%;
	position:relative;
	width:200px;
}
.div_logo
{
	width:209px;
	height:48px;
	position:relative;
}
.div_logo a img
{
	width:100%;
	height:100%;
	border:0;
}
.div_content
{
	position:relative;
	margin-left:200px;
	background:#1F3BE5;
	min-height:100%;
	width:100%;
}
.main 
{
	padding-left:260px;
}
.sidebar2_table,
.sidebar_table
{
	display:table;
	height:100%;
	width:100%;
}
.sidebar2_row,
.sidebar_row
{
	display:table-row;
	height:100%;
	width:100%;
}
.sidebar2_cell,
.sidebar_cell
{
	display:table-cell;
	vertical-align:middle;
	width:100%;
}

.sidebar
{
	.div_menu_active
	{
		z-index:1000;
		position:absolute;
		right:-12px;
		top:2px;
		height:36px;
		width:15px;
		background-image:url(../../images/menu_active.png);
		background-repeat:no-repeat;
		background-position:right center;
	}
	width:315px; 
	float:left; 
	height:100vh; 
	background:#ffffff;
	border-right:4px solid #56bebf;
	ul.main_nav
	{
		margin-left:0px;
		list-style:none;
		li
		{
			position:relative;
			height:40px;
			background:url(../../images/menu_border.png) no-repeat left bottom;
			a
			{
				color:#545556!important;
				font-family:"proxima-nova",arial;	
				font-size:11px;
				font-weight:800;
				text-transform:uppercase;
				line-height:39px;
				display:block;
				text-decoration:none!important;
				&:hover,
				&.active
				{
					text-decoration:none!important;
					color:@brand-primary!important;
				}
				
				/*&.active
				{
					&:before
					{
						content: '\e806';
						.fontello;
						color:@brand-primary!important;
					}
				} */ 
			
			}
			&:last-child
			{
				background:none;
			}
			.div_nieuw
			{
				background:url(../../images/BG_nieuw.png) no-repeat;
				position:absolute;
				width:75px;
				height:32px;
				top:4px;
				right:-11px;
				text-align:center;
				color:#ffffff;
				font-size:10px;
				font-family:"Proxima-nova",arial;
				font-weight:400;
				line-height:24px;
				z-index:1000;
			}
		}
	}
	ul.sub_nav
	{
		margin-top:30px;
		margin-left:0;
		padding-left:0;
		list-style: none;
		text-align: center;
		
		li
		{
			font-family:"proxima-nova",arial;
			font-size:12px;
			display: inline;
			line-height:30px;
			margin: 0;
			a
			{
				color:#a6a6a6;
				display:inline-block;
				&:hover,
				&.active
				{
					color:@brand-primary!important;
				}
			}	
		}
		.span_sub_nav_spacer
		{
			font-size:10px;
			color:#a6a6a6;
			line-height:30px;
			vertical-align:middle;
			padding-bottom:2px;
		}
	}
}
.sidebar .row
{
	padding-left:0;
	padding-right:0;
	margin-left:0;
	margin-right:0;
}
.sidebar2 { 
width:288px; 
float:left; 
height:100vh; 
background:#e0e0e0;
}

.sidebar2
{
	vertical-align:middle;
	margin-top:auto;
	margin-bottom:auto;
	z-index:900;
	.sub_sub_nav, 
	.sub_sub_nav li
	{
		margin-left:0!important;
		padding-left:0!important;
		padding-top:1px;
		padding-bottom:1px;
	}

	.sub_sub_nav 
	{
		
		margin-left:40px;
		padding-left:0;
		list-style:none;
		
		li
		{
			min-height:38px;
			font-family:"proxima-nova",arial;
			font-size:11px;
			color:#6a7884!important;
			position:relative;
			font-weight:400;
			background:url(../../images/menusub_active.png) no-repeat left 40px bottom;
			a
			{
				line-height:35px!important;
				padding-left:40px;
				display:block;
				color:#6a7884!important;
				text-decoration:none;
				&.active
				{
					background-color:#5ea9aa;
					color:#ffffff!important;
				}

			}
			.div_menu_sub_sub_active
			{
				position:absolute;
				z-index:1000;
				right:-13px;
				top:1px;
				width:13px;
				height:35px;
				background:url(../../images/menusub_link_active.png) no-repeat left center;
			
			}
		}
	}
}
.div_taalkeuze
{
	font-family:"proxima-nova",arial;
	text-transform:uppercase;
	font-weight:700;
	color:#cbcbcb;
	text-align:center;
	margin-bottom:20px;
	a
	{
		color:#cbcbcb!important;
		&.active
		{
			color:#56bebf!important;
			text-decoration:underline!important;
		}
	}
}
.div_datum
{
	color:#5ea9aa;
	font-family:"proxima-nova",arial;
	font-size:12px;
	line-height:18px;
	text-align:center;
}
.fontello {

  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
 
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: 1em;
  text-align: center;
 
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
 
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* you can be more comfortable with increased icons size */
  font-size: 100%;
 
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
